import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-scroll";

import navLogo from "../../assets/navlogo.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setCurrentSelectedProject,
  setMediaData,
  setMediaDataBackup,
  setMultipleMediaData,
  setMultipleMediaDataBackup,
  setUsername,
} from "../Home/HomeSlice";
import {
  setErrorModalStatus,
  setLoaderModalStatus,
  setMultipleProfileInfoData,
  setProfileInfoModalStatus,
  setRequestSettingsModal,
} from "../Modals/ModalSlice";
import { ReactComponent as SettingsIcon } from "../../assets/settingscog.svg";
import axios from "axios";
import { logout } from "../../app/firebase";
import { useLocation } from "react-router-dom";
import { ReactComponent as Back } from "../../assets/back.svg";
import { ReactComponent as GetPostData } from "../../assets/getpostdata.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  let mediaData = useAppSelector((state) => state.homeSlice.mediaData);

  let accessToken = process.env.REACT_APP_ACCESS_TOKEN;

  let username = useAppSelector((state) => state.homeSlice.username.value);

  const sinceDate = useAppSelector(
    (state) => state.modalSlice.requestSettingSinceDate.sinceDate,
  );
  const untilDate = useAppSelector(
    (state) => state.modalSlice.requestSettingUntilDate.untilDate,
  );

  const useListStatus = useAppSelector(
    (state) => state.modalSlice.useListStatus.status,
  );

  const useSingleProfile = useAppSelector(
    (state) => state.modalSlice.useSingleProfile.status,
  );

  const [useSingleProfileStatus, setUseSingleProfile] =
    useState(useSingleProfile);

  const currentSelectedProject = useAppSelector(
    (state) => state.homeSlice.currentSelectedProject,
  );

  useEffect(() => {
    console.log("CSP:", currentSelectedProject);
    //Added if block to prevent error - when page was loaded /home directly from localhost the app crashed
    if (currentSelectedProject.projectList[0] !== undefined) {
      dispatch(
        setUsername({
          value: currentSelectedProject.projectList[0],
        }),
      );
    } else if (
      currentSelectedProject.projectList[0] === undefined &&
      window.location.href.includes("/home")
    ) {
      window.location.replace("/projects");
    }
  }, [currentSelectedProject]);

  const handleNav = () => {
    setIsOpen(!isOpen);
  };

  const usernameHandler = (e) => {
    dispatch(
      setUsername({
        value: e.target.value,
      }),
    );
  };

  const openSettingsModal = () => {
    dispatch(
      setRequestSettingsModal({
        status: true,
      }),
    );
  };

  const getAPIWithUserName = async () => {
    try {
      // let stored = localStorage.getItem('localData');
      // if(stored){

      // }

      // Add only date based api call

      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Fetching Data From Instagram",
        }),
      );

      let url = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${username}){profile_picture_url,name,followers_count,follows_count,website,biography,media_count,media.since(${sinceDate}).until(${untilDate}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;
      console.log("URL: ", url);

      // Replace tempRes with the actual API call
      const response = await axios.get(url);
      console.log("just after req:", response);
      // const response = tempRes;
      let mediaInfo = [];
      let name = response.data.business_discovery.name;
      let profile_picture_url =
        response.data.business_discovery.profile_picture_url;
      let followersCount = response.data.business_discovery.followers_count;
      let followsCount = response.data.business_discovery.follows_count;
      let website = response.data.business_discovery.website;
      let biography = response.data.business_discovery.biography;
      let id = response.data.business_discovery.id;
      let totalMediaCount = response.data.business_discovery.media_count;

      console.log("FIRST RESPONSE: ", response);

      let profile = {
        status: false,
        isClickable: true,
        profile_picture_url: profile_picture_url,
        name: name,
        username: username,
        id: id,
        followersCount: followersCount,
        followsCount: followsCount,
        website: website,
        biography: biography,
        totalMediaCount: totalMediaCount,
      };

      dispatch(setProfileInfoModalStatus(profile));

      if (response.data.business_discovery.media === undefined) {
        dispatch(
          setErrorModalStatus({
            status: true,
            message:
              "No Data Retrived - Try with Other Profile / Time Duration",
          }),
        );
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "Fetching Data From Instagram",
          }),
        );
        dispatch(setMediaData([]));
        return;
      }

      if (response.data.business_discovery.media.data === undefined) {
        //Show modal and return
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "",
          }),
        );

        return;
      }

      mediaInfo.push(...response.data.business_discovery.media.data);

      let afterKey = null;

      if (response.data.business_discovery.media.paging !== undefined) {
        if (
          response.data.business_discovery.media.paging.cursors !== undefined
        ) {
          if (
            response.data.business_discovery.media.paging.cursors.after !==
            undefined
          ) {
            afterKey =
              response.data.business_discovery.media.paging.cursors.after;
          }
        }

        if (
          response.data.business_discovery.media.paging.cursors.after ===
          undefined
        ) {
          afterKey = null;
        }
      }

      while (afterKey !== null) {
        console.log("Looping");
        let newURL = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${username}){media.since(${sinceDate}).until(${untilDate}).after(${afterKey}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;
        console.log("URL: ", newURL);
        const nextResponse = await axios.get(newURL);
        console.log(nextResponse);
        mediaInfo.push(...nextResponse.data.business_discovery.media.data);
        if (
          nextResponse.data.business_discovery.media.paging.cursors.after !==
          undefined
        ) {
          afterKey =
            nextResponse.data.business_discovery.media.paging.cursors.after;
        } else {
          afterKey = null;
        }
        console.log("URL: ", url);
      }

      // After getting all posts - store them as object in local storage and add logic to avoid recall

      dispatch(setMediaData([...mediaInfo]));
      dispatch(setMediaDataBackup([...mediaInfo]));
      //store data along with since and until date.

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );

      let localData = {
        username: username,
        mediaData: [...mediaInfo],
        lastSinceDate: sinceDate,
        lastUntilDate: untilDate,
      };

      localStorage.setItem("localData", localData);
    } catch (error) {
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 110
      ) {
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "",
          }),
        );

        dispatch(
          setErrorModalStatus({
            status: true,
            message: "@" + username + " Is Not A Business Profile",
          }),
        );
        return;
      }

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );

      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Something Went Wrong",
        }),
      );

      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    console.log("L", useSingleProfile);
  }, [useSingleProfile]);

  const getAPIWithListsAllUsernames = async () => {
    try {
      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Fetching Data From Instagram",
        }),
      );

      let arrayTest = [];
      let arrayTestBackup = [];
      let allUsernames = currentSelectedProject.projectList;
      let newResponseArrayMedia = [];
      let multipleProfileData = [];
      console.log(allUsernames);

      newResponseArrayMedia = await Promise.all(
        allUsernames.map(async (item, index) => {
          let url = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${item}){profile_picture_url,name,followers_count,follows_count,website,biography,media_count,media.since(${sinceDate}).until(${untilDate}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;

          console.log("URL: ", url);

          try {
            const response = await axios.get(url);
            console.log("just after req:", response);

            let mediaInfo = [];
            let name = response.data.business_discovery.name;
            let profile_picture_url =
              response.data.business_discovery.profile_picture_url;
            let followersCount =
              response.data.business_discovery.followers_count;
            let followsCount = response.data.business_discovery.follows_count;
            let website = response.data.business_discovery.website;
            let biography = response.data.business_discovery.biography;
            let id = response.data.business_discovery.id;
            let totalMediaCount = response.data.business_discovery.media_count;

            console.log("FIRST RESPONSE: ", response);

            let profile = {
              status: false,
              isClickable: true,
              profile_picture_url: profile_picture_url,
              name: name,
              username: item,
              id: id,
              followersCount: followersCount,
              followsCount: followsCount,
              website: website,
              biography: biography,
              totalMediaCount: totalMediaCount,
            };

            multipleProfileData.push(profile);
            dispatch(setProfileInfoModalStatus(profile));

            if (response.data.business_discovery.media === undefined) {
              if (allUsernames.length < 1) {
                dispatch(
                  setErrorModalStatus({
                    status: true,
                    message:
                      "No Data Retrieved - Try with Other Profile / Time Duration",
                  }),
                );
              }
              dispatch(
                setLoaderModalStatus({
                  status: false,
                  message: "Fetching Data From Instagram",
                }),
              );
              dispatch(setMediaData([]));
              return;
            }

            if (response.data.business_discovery.media.data === undefined) {
              dispatch(
                setLoaderModalStatus({
                  status: false,
                  message: "",
                }),
              );
              return;
            }

            mediaInfo.push(...response.data.business_discovery.media.data);
            let afterKey =
              response.data.business_discovery.media.paging?.cursors?.after ||
              null;

            while (afterKey !== null) {
              console.log("Looping");
              let newURL = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${item}){media.since(${sinceDate}).until(${untilDate}).after(${afterKey}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;

              const nextResponse = await axios.get(newURL);
              console.log(nextResponse);
              mediaInfo.push(
                ...nextResponse.data.business_discovery.media.data,
              );

              afterKey =
                nextResponse.data.business_discovery.media.paging?.cursors
                  ?.after || null;
            }

            dispatch(
              setLoaderModalStatus({
                status: false,
                message: "",
              }),
            );

            let localData = {
              username: item,
              mediaData: [...mediaInfo],
              lastSinceDate: sinceDate,
              lastUntilDate: untilDate,
            };

            localStorage.setItem("localData", JSON.stringify(localData));

            return mediaInfo;
          } catch (error) {
            console.error(`Error fetching data for ${item}:`, error);
            return []; // Return empty array on error to avoid breaking Promise.all
          }
        }),
      );
      //END OF LOOP HERE

      //Add profile info data

      dispatch(setMultipleProfileInfoData([...multipleProfileData]));

      console.log("All media info:", newResponseArrayMedia);
      //Flatten the array / combine arrays
      let flattenedArray = newResponseArrayMedia.flat().filter(Boolean);
      console.log("FA:", flattenedArray);

      dispatch(setMediaData([...flattenedArray]));
      dispatch(setMediaDataBackup([...flattenedArray]));
    } catch (error) {
      console.log(error);
    }
  };

  const backToProjectList = () => {
    window.location.replace("/projects");
    dispatch(
      setCurrentSelectedProject({
        projectName: "",
        projectList: [],
      }),
    );
  };
  const usernameOptionHandler = (e) => {
    dispatch(
      setUsername({
        value: e.target.value,
      }),
    );
  };

  // useEffect(()=>{
  //   console.log("MD:",mediaData)
  //   if(mediaData.length!==undefined){
  //     if(mediaData.length===0){
  //       dispatch(setErrorModalStatus({
  //         status:true,
  //         message:"No Data Retrived"
  //       }))
  //     }
  //   }
  // },[dispatch, mediaData])

  useEffect(() => {
    console.log(username);
  }, [username]);

  return (
    <nav class="bg-instagram-dark fixed left-0 top-0 right-0 navbar-top z-50 border-instagram-grey border-b border-b-1 px-2 sm:px-2 py-2.5 rounded dark:bg-instagram-dark">
      <div class="container flex flex-wrap justify-between mx-auto">
        {/* <button
          class="w-fit h-10 my-2 text-instagram-dark bg-instagram-lightgrey  hover:bg-transparent  hover:text-instagram-light ring-2 ring-instagram-light font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
          onClick={backToProjectList}
        >
          {"<"}
        </button> */}

        {location.pathname !== "/login" &&
          location.pathname !== "/register" &&
          location.pathname !== "/projects" &&
          location.pathname !== "/" &&
          location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-of-service" && (
            <button
              className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
              onClick={backToProjectList}
            >
              <Back width={20} height={20} />
            </button>
          )}

        <a href="/projects" class="flex items-center">
          <img
            src={navLogo}
            class="mr-2 sm:h-10 -mt-2 invert"
            alt="unbubble GmbH Logo"
          />
        </a>

        {location.pathname !== "/login" &&
          location.pathname !== "/register" &&
          location.pathname !== "/projects" &&
          location.pathname !== "/" &&
          location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-of-service" && (
            <div className="flex flex-row justify-center items-center gap-10">
              {useListStatus === true &&
                currentSelectedProject.projectList !== undefined && (
                  <select
                    onChange={usernameOptionHandler}
                    className="w-[500px] h-10 px-5 py-2.5 text-md rounded-lg font-medium outline outline-4 outline-instagram-lightgrey"
                  >
                    {currentSelectedProject.projectList.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                )}

              {useListStatus === false && (
                <input
                  className="w-[500px] h-10 px-5 py-2.5 rounded-lg font-medium outline outline-4 outline-instagram-lightgrey"
                  placeholder="Enter Instagram Username"
                  onChange={usernameHandler}
                />
              )}

              <button
                onClick={openSettingsModal}
                class="w-fit unbubble text-instagram-dark bg-instagram-lightgrey  hover:bg-transparent  hover:text-instagram-light ring-2 ring-instagram-light font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
              >
                <SettingsIcon />
              </button>

              <button
                // onClick={getAPIWithUserName}
                onClick={
                  useSingleProfile
                    ? getAPIWithUserName
                    : getAPIWithListsAllUsernames
                }
                className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
              >
                <span>Get Post Data</span>
                <GetPostData width={20} height={20} />
              </button>
            </div>
          )}

        {location.pathname !== "/login" &&
          location.pathname !== "/register" &&
          location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-of-service" && (
            <div className="flex flex-row justify-end">
              <button
                type="button"
                className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
                onClick={logout}
              >
                <span>Logout</span>
                <LogoutIcon width={20} height={20} />
              </button>
            </div>
          )}
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="xl:hidden" id="mobile-menu-4">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link activeClass="active" to="Contact" spy={true} smooth={true}>
                <a
                  href="#Contact"
                  className="text-gray-300 hover:bg-customprimary hover:text-customsecondary block px-3 py-2 rounded-md text-base font-extrabold"
                >
                  Logout
                </a>
              </Link>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export default Nav;
