import axios from "axios";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setCommentsSelected,
  setCurrentSelectedProject,
  setDateSelected,
  setEngagementSelected,
  setIsDescending,
  setIsDescendingComments,
  setIsEngagement,
  setIsLatest,
  setIsReach,
  setIsUsername,
  setLikesSelected,
  setMediaData,
  setMediaDataBackup,
  setMediaTypeSelected,
  setProfileData,
  setReachSelected,
  setUsername,
  setUsernameSelected,
} from "./HomeSlice";
import {
  setCurrentProfileInfoDataIndex,
  setErrorModalStatus,
  setLoaderModalStatus,
  setProfileInfoModalStatus,
  setRequestSettingsModal,
} from "../Modals/ModalSlice";
import { RequestSettingsModal } from "../Modals/RequestSettingsModal";
import { ProfileInfoModal } from "../Modals/ProfileInfoModal";
import { LoaderModal } from "../Modals/LoaderModal";
import { ErrorModal } from "../Modals/ErrorModal";
import { tempData, tempRes } from "./tempData";
import { auth } from "../../app/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";
import { ReactComponent as ArrowUp } from "../../assets/arrow-button-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrow-button-down.svg";

import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
import { ReactComponent as User } from "../../assets/user.svg";

import { ReactComponent as MediaType } from "../../assets/menu.svg";
import { ReactComponent as Like } from "../../assets/like.svg";
import { ReactComponent as Comment } from "../../assets/comment.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { ReactComponent as EngagementIcon } from "../../assets/engagement.svg";
import { ReactComponent as ReachIcon } from "../../assets/reach.svg";
import "./Home.css";
const Home = () => {
  const dispatch = useAppDispatch();

  const [isMediaIconWhite, setIsMediaIconWhite] = useState(false);
  const [isLikeIconWhite, setIsLikeIconWhite] = useState(false);
  const [isCommentIconWhite, setIsCommentIconWhite] = useState(false);
  const [isCalendarIconWhite, setIsCalendarIconWhite] = useState(false);
  const [isEngagementIconWhite, setIsEngagementIconWhite] = useState(false);
  const [isReachIconWhite, setIsReachIconWhite] = useState(false);
  const [isUsernameIconWhite, setIsUsernameIconWhite] = useState(false);

  const [searchMessage, setSearchMessage] = useState("");

  let username = useAppSelector((state) => state.homeSlice.username.value);
  let output = useAppSelector((state) => state.homeSlice.output.value);
  let profileData = useAppSelector((state) => state.homeSlice.profileData);
  // let mediaData = useAppSelector((state) => state.homeSlice.mediaData);
  // let mediaDataBackup = useAppSelector(
  //   (state) => state.homeSlice.mediaDataBackup,
  // );

  let mediaData = useAppSelector((state) => state.homeSlice.mediaData);
  let mediaDataBackup = useAppSelector(
    (state) => state.homeSlice.mediaDataBackup,
  );

  let currentProfileInfoData = useAppSelector(
    (state) => state.modalSlice.profileInfoModal,
  );

  let currentProfileInfoDataIndex = useAppSelector(
    (state) => state.modalSlice.currentProfileInfoDataIndex,
  );

  let multipleProfileInfoData = useAppSelector(
    (state) => state.modalSlice.multipleProfileInfoData,
  );

  let profileInfoModalName = useAppSelector(
    (state) => state.modalSlice.profileInfoModal.name,
  );
  let profileInfoModal = useAppSelector(
    (state) => state.modalSlice.profileInfoModal,
  );

  const sinceDate = useAppSelector(
    (state) => state.modalSlice.requestSettingSinceDate.sinceDate,
  );
  const untilDate = useAppSelector(
    (state) => state.modalSlice.requestSettingUntilDate.untilDate,
  );
  const dateFilterStatus = useAppSelector(
    (state) => state.modalSlice.requestSettingDateFilterStatus.status,
  );

  let isDescending = useAppSelector((state) => state.homeSlice.isDescending);
  let isDescendingComments = useAppSelector(
    (state) => state.homeSlice.isDescendingComments,
  );
  let isLatest = useAppSelector((state) => state.homeSlice.isLatest);
  let isEngagement = useAppSelector((state) => state.homeSlice.isEngagement);
  let isReach = useAppSelector((state) => state.homeSlice.isReach);
  let isUsername = useAppSelector((state) => state.homeSlice.isUsername);

  let likesSelected = useAppSelector((state) => state.homeSlice.likesSelected);
  let commentsSelected = useAppSelector(
    (state) => state.homeSlice.commentsSelected,
  );
  let dateSelected = useAppSelector((state) => state.homeSlice.dateSelected);
  let engagementSelected = useAppSelector(
    (state) => state.homeSlice.engagementSelected,
  );

  let reachSelectedSelected = useAppSelector(
    (state) => state.homeSlice.reachSelectedSelected,
  );

  let usernameSelected = useAppSelector(
    (state) => state.homeSlice.usernameSelected,
  );

  let mediaTypeSelected = useAppSelector(
    (state) => state.homeSlice.mediaTypeSelected,
  );

  let accessToken = process.env.REACT_APP_ACCESS_TOKEN;

  const location = useLocation();
  const { projectName, projectList } = location.state || {};

  useEffect(() => {
    console.log(projectName);
    console.log(projectList);
    dispatch(
      setCurrentSelectedProject({
        projectName: projectName,
        projectList: projectList,
      }),
    );
  }, [projectName, projectList]);

  const usernameHandler = (e) => {
    dispatch(
      setUsername({
        value: e.target.value,
      }),
    );
  };

  useEffect(() => {
    console.log(mediaData);
  }, [mediaData]);

  useEffect(() => {
    // dispatch(
    //   setProfileInfoModalStatus({
    //     status: false,
    //     isClickable: true,
    //     profile_picture_url: tempRes.data.business_discovery.profile_picture_url,
    //     name:  tempRes.data.business_discovery.name,
    //     username: tempRes.data.business_discovery.username,
    //     id: tempRes.data.business_discovery.id,
    //     followersCount: tempRes.data.business_discovery.followers_count,
    //     followsCount: tempRes.data.business_discovery.follows_count,
    //     website: tempRes.data.business_discovery.website,
    //     biography: tempRes.data.business_discovery.biography,
    //     totalMediaCount: tempRes.data.business_discovery.totalMediaCount,
    //   }),
    // );
    // dispatch(setMediaData([...tempData]));
    // dispatch(setMediaDataBackup([...tempData]));
  }, []);

  const goToPostOnInstagram = (link) => {
    console.log(link);
    window.open(link, "_blank");
  };

  const openSettingsModal = () => {
    dispatch(
      setRequestSettingsModal({
        status: true,
      }),
    );
  };

  const openProfileInfoModal = () => {
    let tempProfileData = { ...profileInfoModal };
    tempProfileData.status = true;
    dispatch(setProfileInfoModalStatus(tempProfileData));
  };

  const handleSelection = (type) => {
    const defaultCss = "";
    const selectedCss = "bg-instagram-darkgrey text-instagram-lightgrey";

    setIsMediaIconWhite(type === "mediatype");
    setIsLikeIconWhite(type === "likes");
    setIsCommentIconWhite(type === "comments");
    setIsCalendarIconWhite(type === "date");
    setIsEngagementIconWhite(type === "engagement");
    setIsReachIconWhite(type === "reach");
    setIsUsernameIconWhite(type === "username");
    dispatch(
      setLikesSelected({
        css: type === "likes" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setCommentsSelected({
        css: type === "comments" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setDateSelected({
        css: type === "date" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setEngagementSelected({
        css: type === "engagement" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setReachSelected({
        css: type === "reach" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setUsernameSelected({
        css: type === "username" ? selectedCss : defaultCss,
      }),
    );

    dispatch(
      setMediaTypeSelected({
        css: type === "mediatype" ? selectedCss : defaultCss,
      }),
    );
  };

  const setIconState = (icon) => {
    setIsMediaIconWhite(icon === "media");
    setIsLikeIconWhite(icon === "like");
    setIsCommentIconWhite(icon === "comment");
    setIsCalendarIconWhite(icon === "calendar");
    setIsEngagementIconWhite(icon === "engagement");
    setIsReachIconWhite(icon === "reach");
  };

  const sortByLikes = () => {
    let dataTemp = [...mediaData];
    let sortedArray;

    if (isDescending) {
      sortedArray = dataTemp.sort((a, b) => b.like_count - a.like_count);
    } else {
      sortedArray = dataTemp.sort((a, b) => a.like_count - b.like_count);
    }

    handleSelection("likes");

    dispatch(setIsDescending(!isDescending));

    dispatch(setMediaData([...sortedArray]));
  };

  const sortByComments = () => {
    let dataTemp = [...mediaData];
    let sortedArray;
    handleSelection("comments");

    if (isDescendingComments) {
      sortedArray = dataTemp.sort(
        (a, b) => b.comments_count - a.comments_count,
      );
    } else {
      sortedArray = dataTemp.sort(
        (a, b) => a.comments_count - b.comments_count,
      );
    }

    dispatch(setIsDescendingComments(!isDescendingComments));

    dispatch(setMediaData([...sortedArray]));
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState("all");

  const handleMediaTypeChange = (event) => {
    setSelectedMediaType(event.target.value);
    setDropdownOpen(false);
    sortByMediaType(event.target.value);
  };

  useEffect(() => {
    if (dropdownOpen) {
      handleSelection("mediatype");
    }
  }, [dropdownOpen]);

  const findMatchingIndex = (array, obj) => {
    return array.findIndex((item) => item.id === obj.id);
  };

  useEffect(() => {
    console.log("MPID:", multipleProfileInfoData);
    console.log("CURRENTPROFILE:", currentProfileInfoData);
    const matchedIndex = findMatchingIndex(
      multipleProfileInfoData,
      currentProfileInfoData,
    );
    if (matchedIndex !== -1) {
      console.log(`Matched at index: ${matchedIndex}`);
      dispatch(setCurrentProfileInfoDataIndex(matchedIndex));
    } else {
      console.log("No match found");
    }
  }, [multipleProfileInfoData]);

  // const sortByMediaType = (selectedMediaType) => {

  //   let dataTemp = [...mediaData];
  //   let filteredData = [];
  //   if (selectedMediaType === "carousel") {
  //     filteredData = dataTemp.filter(
  //       (item) => item.media_type === "CAROUSEL_ALBUM",
  //     );
  //   } else if (selectedMediaType === "image") {
  //     filteredData = dataTemp.filter((item) => item.media_type === "IMAGE");
  //   } else if (selectedMediaType === "video") {
  //     filteredData = dataTemp.filter((item) => item.media_type === "VIDEO");
  //   } else if (selectedMediaType === "all") {
  //     filteredData = [...mediaDataBackup];
  //   }
  //   dispatch(setMediaData([...filteredData]));
  // };

  const sortByMediaType = (selectedMediaType) => {
    let dataTemp = [...mediaDataBackup]; // Always start from the unfiltered data
    let filteredData = [];

    if (selectedMediaType === "carousel") {
      filteredData = dataTemp.filter(
        (item) => item.media_type === "CAROUSEL_ALBUM",
      );
    } else if (selectedMediaType === "image") {
      filteredData = dataTemp.filter((item) => item.media_type === "IMAGE");
    } else if (selectedMediaType === "video") {
      filteredData = dataTemp.filter((item) => item.media_type === "VIDEO");
    } else if (selectedMediaType === "all") {
      filteredData = [...mediaDataBackup]; // Restore original data if "all" is selected
    }

    dispatch(setMediaData([...filteredData]));
  };

  const sortByDate = () => {
    let dataTemp = [...mediaData];
    let sortedLatest;
    if (isLatest) {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      );
    } else {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      );
    }
    handleSelection("date");

    dispatch(setIsLatest(!isLatest));

    dispatch(setMediaData([...sortedLatest]));
  };

  const sortByEngagement = () => {
    // Create a copy of the mediaData array to avoid mutating the original state
    let dataTemp = [...mediaData];

    // Calculate the engagement rate for each post and sort the array
    let sortedArray = dataTemp.sort((a, b) => {
      // Calculate engagement rate for post 'a'
      let engagementRateA =
        (a.like_count + a.comments_count) / profileInfoModal.followersCount;

      // Calculate engagement rate for post 'b'
      let engagementRateB =
        (b.like_count + b.comments_count) / profileInfoModal.followersCount;

      // Determine sort order: Ascending if isEngagement is true, else Descending
      if (isEngagement) {
        return engagementRateA - engagementRateB; // Ascending order
      } else {
        return engagementRateB - engagementRateA; // Descending order
      }
    });
    handleSelection("engagement");

    // Toggle the sorting order state
    dispatch(setIsEngagement(!isEngagement));

    // Update the media data with the sorted array
    dispatch(setMediaData([...sortedArray]));
  };

  let calculateEngagementRate = (likes, comments) => {
    let followers = profileInfoModal.followersCount;
    let engagementRate = (likes + comments) / followers;
    return engagementRate.toFixed(2);
  };

  //

  let sortByUsernameAlphabetically = () => {
    let dataTemp = [...mediaData];
    let sortedArray = dataTemp.sort((a, b) => {
      // Compare usernames alphabetically
      if (a.username.toLowerCase() < b.username.toLowerCase()) {
        return isUsername ? -1 : 1; // Ascending if isReach is true, else Descending
      }
      if (a.username.toLowerCase() > b.username.toLowerCase()) {
        return isUsername ? 1 : -1; // Ascending if isReach is true, else Descending
      }
      return 0; // If they are equal
    });
    handleSelection("username");
    dispatch(setIsUsername(!isUsername));
    dispatch(setMediaData([...sortedArray]));
  };

  let sortByReach = () => {
    // Create a copy of the mediaData array to avoid mutating the original state
    let dataTemp = [...mediaData];

    // Calculate the reach for each post and sort the array
    let sortedArray = dataTemp.sort((a, b) => {
      // Calculate reach for post 'a'
      let reachA = a.like_count + a.comments_count;

      // Calculate reach for post 'b'
      let reachB = b.like_count + b.comments_count;

      // Determine sort order: Ascending if isReach is true, else Descending
      if (isReach) {
        return reachA - reachB; // Ascending order
      } else {
        return reachB - reachA; // Descending order
      }
    });
    handleSelection("reach");

    // Toggle the sorting order state
    dispatch(setIsReach(!isReach));

    // Update the media data with the sorted array
    dispatch(setMediaData([...sortedArray]));
  };

  let calculateReach = (likes, comments) => {
    // Estimate reach based on a simple multiplication of engagement by a factor
    // Assume reach is proportional to the number of engagements (likes + comments)
    let reach = likes + comments;

    // Function to format the number into k, m, etc.
    const formatNumber = (num) => {
      if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "b";
      } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "m";
      } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
      } else {
        return num.toString();
      }
    };

    return formatNumber(reach);
  };

  const openProfilePicture = () => {
    const link = profileInfoModal.profile_picture_url;
    window.open(link, "_blank");
  };

  const visitProfile = () => {
    const link = `https://instagram.com/${profileInfoModal.username || "instagram"}`;
    window.open(link, "_blank");
  };

  const [user, loading] = useAuthState(auth);

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return num.toString();
    }
  }

  useEffect(() => {
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      return;
    } else {
      window.location.replace("/login");
    }
  }, [user, loading]);

  useEffect(() => {}, []);

  const clickedUp = () => {
    let currentIndex = currentProfileInfoDataIndex;
    if (currentIndex > 0) {
      currentIndex--;

      dispatch(
        setProfileInfoModalStatus(multipleProfileInfoData[currentIndex]),
      );

      dispatch(setCurrentProfileInfoDataIndex(currentIndex));
    } else {
      console.log("Already on first item");
    }
  };

  const clickedDown = () => {
    let currentIndex = currentProfileInfoDataIndex;
    if (currentIndex < multipleProfileInfoData.length - 1) {
      currentIndex++; // Move to the next item
      dispatch(
        setProfileInfoModalStatus(multipleProfileInfoData[currentIndex]),
      );
      dispatch(setCurrentProfileInfoDataIndex(currentIndex));
    } else {
      console.log("Already on last item");
    }
  };

  const searchTermThroughPosts = (e) => {
    let term = e.target.value.toLowerCase();
    console.log(term);
    console.log(term.length);

    if (term.length === 0) {
      dispatch(setMediaData([...mediaDataBackup]));
      setSearchMessage("");
      return;
    }

    let dataTemp = [...mediaData];
    dataTemp = dataTemp.filter((item) =>
      item.caption.toLowerCase().includes(term),
    );
    if (dataTemp.length > 0) {
      let filteredArray = [...dataTemp];
      dispatch(setMediaData(filteredArray));
      setSearchMessage(filteredArray.length + " results found");
    } else {
      setSearchMessage("No match found - restored entries");
      dispatch(setMediaData([...mediaDataBackup]));
    }
  };

  return (
    <>
      <div className="flex justify-center items-center mt-24 flex-col">
        <div className="flex">
          <div className="w-[1100px] border border-4 border-instagram-lightgrey px-5 -py-2 rounded-xl">
            <div className="flex flex-row gap-10">
              <div className="w-32 h-32 flex flex-col justify-center pt-20">
                {profileInfoModal.profile_picture_url && (
                  <img
                    src={profileInfoModal.profile_picture_url}
                    className="rounded-2xl border border-4 border-instagram-lightgrey"
                    alt="Profile"
                    onClick={openProfilePicture}
                  />
                )}
              </div>

              <div className="flex flex-col justify-center">
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  Name
                </h3>
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  Username
                </h3>
              </div>
              <div className="flex flex-col justify-center">
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.name}
                </h3>
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.username || "instagram"}
                </h3>
              </div>

              <div className="flex flex-col justify-center">
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  ID
                </h3>
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  Followers
                </h3>{" "}
              </div>

              <div className="flex flex-col justify-center">
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.id}
                </h3>
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.followersCount}
                </h3>{" "}
              </div>

              <div className="flex flex-col justify-center">
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  Following
                </h3>
                <h3 className="text-instagram-light font-semibold text-lg text-left">
                  Total Media
                </h3>
              </div>

              <div className="flex flex-col justify-center">
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.followsCount}
                </h3>
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-right">
                  {profileInfoModal.totalMediaCount}
                </h3>
              </div>
            </div>

            <div className="flex flex-row gap-10 justify-center px-2 py-2 rounded-xl">
              <div className="flex flex-col justify-center">
                {/* <h3 className="text-instagram-light font-semibold text-lg text-center">
                Bio
              </h3> */}
                <h3 className="text-instagram-light font-semibold text-lg text-center">
                  Website
                </h3>
              </div>

              <div className="flex flex-col justify-center">
                {/* <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-left">
                {profileInfoModal.biography}
              </h3> */}
                <h3 className="underline decoration-instagram-lightgrey decoration-solid	decoration-2	underline-offset-2 text-instagram-light font-semibold text-lg text-left">
                  {profileInfoModal.website}
                </h3>
              </div>

              <button
                className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
                onClick={visitProfile}
              >
                <span>Visit Profile</span>
                <ProfileIcon width={24} height={20} />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center text-white mx-5">
            <button onClick={clickedUp}>
              <ArrowUp width={30} height={30} id="arrowUp" />
            </button>

            <p className="text-instagram-light font-semibold text-sm">
              {currentProfileInfoDataIndex + 1} /{" "}
              {multipleProfileInfoData.length === 0
                ? 1
                : multipleProfileInfoData.length}
            </p>

            <button onClick={clickedDown}>
              <ArrowDown width={30} height={30} id="arrowDown" />
            </button>
          </div>
        </div>

        <div className="my-4 mx-10 flex flex-col w-fit">
          <div className="flex justify-between items-center">
            <div className="flex w-3/4 items-center gap-2">
              <textarea
                onChange={searchTermThroughPosts}
                class="w-full max-w-sm ml-1 h-12 p-3 outline outline-4 outline-instagram-lightgrey font-medium text-md rounded-lg bg-white shadow transition duration-300 resize-none"
                placeholder="Enter search term"
              ></textarea>

              <p className="text-instagram-light mx-4 font-semibold">
                {searchMessage}
              </p>
            </div>

            <p className="text-instagram-light text-end mr-2 font-semibold w-1/4">
              {mediaDataBackup.length} Total Posts
            </p>
          </div>

          <div className="flex flex-row justify-between w-full max-w-[1200px] bg-instagram-lightgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-dark font-medium px-5 py-5">
            <div className="relative">
              <button
                className={
                  `flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md ` +
                  mediaTypeSelected.css
                }
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <p className="unselectable">Media</p>
                <MediaType
                  width={24}
                  height={20}
                  className={isMediaIconWhite ? "invert" : "currentColor"}
                />
              </button>
              {dropdownOpen && (
                <div className="absolute bg-instagram-lightgrey rounded-lg mt-2 pb-2 w-[150px]">
                  <label className="flex flex-row px-4 text-instagram-dark font-medium hover:bg-instagram-darkgrey hover:text-instagram-lightgrey hover:rounded-md cursor-pointer mx-2">
                    <input
                      type="radio"
                      name="mediaType"
                      value="all"
                      checked={selectedMediaType === "all"}
                      onChange={handleMediaTypeChange}
                      className="mr-2"
                    />
                    <span className="block mx-2 py-2 text-right font-medium">
                      All
                    </span>
                  </label>
                  <label className="flex flex-row px-4 text-instagram-dark font-medium hover:bg-instagram-darkgrey hover:text-instagram-lightgrey hover:rounded-md cursor-pointer mx-2">
                    <input
                      type="radio"
                      name="mediaType"
                      value="carousel"
                      checked={selectedMediaType === "carousel"}
                      onChange={handleMediaTypeChange}
                      className="mr-2"
                    />
                    <span className="block mx-2 py-2  font-medium">
                      Carousel
                    </span>
                  </label>
                  <label className="flex flex-row px-4 text-instagram-dark font-medium hover:bg-instagram-darkgrey hover:text-instagram-lightgrey hover:rounded-md cursor-pointer mx-2">
                    <input
                      type="radio"
                      name="mediaType"
                      value="image"
                      checked={selectedMediaType === "image"}
                      onChange={handleMediaTypeChange}
                      className="mr-2"
                    />
                    <span className="block mx-2 py-2  font-medium">Image</span>
                  </label>
                  <label className="flex flex-row px-4 text-instagram-dark font-medium hover:bg-instagram-darkgrey hover:text-instagram-lightgrey hover:rounded-md cursor-pointer mx-2">
                    <input
                      type="radio"
                      name="mediaType"
                      value="video"
                      checked={selectedMediaType === "video"}
                      onChange={handleMediaTypeChange}
                      className="mr-2"
                    />
                    <span className="block mx-2 py-2 font-medium">Video</span>
                  </label>
                </div>
              )}
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${likesSelected.css}`}
              onClick={sortByLikes}
            >
              <p className="unselectable">Likes</p>
              <Like
                width={24}
                height={20}
                className={isLikeIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${commentsSelected.css}`}
              onClick={sortByComments}
            >
              <p className="unselectable">Comments</p>
              <Comment
                width={24}
                height={20}
                className={isCommentIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${dateSelected.css}`}
              onClick={sortByDate}
            >
              <p className="unselectable">Date</p>
              <Calendar
                width={24}
                height={20}
                className={isCalendarIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${usernameSelected.css}`}
              onClick={sortByUsernameAlphabetically}
            >
              <p className="unselectable">Username</p>
              <User
                width={24}
                height={20}
                className={isUsernameIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${engagementSelected.css}`}
              onClick={sortByEngagement}
            >
              <p className="unselectable">Engagement</p>
              <EngagementIcon
                width={24}
                height={20}
                className={isEngagementIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${reachSelectedSelected.css}`}
              onClick={sortByReach}
            >
              <p className="unselectable">Reach</p>
              <ReachIcon
                width={24}
                height={20}
                className={isReachIconWhite ? "invert" : "currentColor"}
              />
            </div>
          </div>

          {mediaData !== undefined && mediaData.length > 0 ? (
            mediaData.map((item) => (
              <button
                className="flex flex-row justify-between w-full bg-instagram-darkgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-lightgrey font-medium hover:bg-instagram-lightgrey hover:text-instagram-darkgrey px-5 py-5"
                key={item.id}
                onClick={() => goToPostOnInstagram(item.permalink)}
              >
                <div className="flex-1 text-left ml-5">
                  {item.media_type === "CAROUSEL_ALBUM" ? (
                    <p>Carousel Album</p>
                  ) : item.media_type === "IMAGE" ? (
                    <p>Image</p>
                  ) : item.media_type === "VIDEO" ? (
                    <p>Video</p>
                  ) : (
                    <p>Unknown media type</p>
                  )}
                </div>

                <div className="flex-1 text-left -ml-5">
                  {item.like_count !== undefined && (
                    <p>{formatNumber(item.like_count)} Likes</p>
                  )}

                  {item.like_count === undefined && <p>Likes Hidden</p>}
                </div>
                <div className="flex-1 text-left -ml-4">
                  <p>{formatNumber(item.comments_count)} Comments</p>
                </div>
                <div className="flex-1 text-center -ml-5">
                  <p>
                    {new Date(item.timestamp).getFullYear() +
                      "-" +
                      (new Date(item.timestamp).getMonth() + 1) +
                      "-" +
                      new Date(item.timestamp).getDate()}
                  </p>
                </div>

                <div
                  className="flex-1 text-center -ml-5
                "
                >
                  <p>{item.username}</p>
                </div>

                <div className="flex-1 text-center">
                  <p>
                    {calculateEngagementRate(
                      item.like_count || 0,
                      item.comments_count,
                    ) + "%"}
                  </p>
                </div>

                <div className="flex-1 text-right mr-5">
                  <p>
                    {calculateReach(item.like_count || 0, item.comments_count) +
                      " Users"}
                  </p>
                </div>
              </button>
            ))
          ) : (
            <button className="flex flex-row justify-center w-full max-w-[1172.43px] bg-instagram-darkgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-lightgrey font-medium hover:bg-instagram-lightgrey hover:text-instagram-darkgrey px-5 py-5">
              Please enter username and click 'Get Post Data' to retrieve media.
            </button>
          )}
        </div>
      </div>
      <LoaderModal />
      <ErrorModal />
      <ProfileInfoModal />
      <RequestSettingsModal /> <LoaderModal />
    </>
  );
};

export default Home;
