import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectList: [],
  isSortedByProjectNameAscending: true,
  isSortedByTotalAccountsAscending: true,
  isCreatedDateLatest: true,
  projectNameSelected: {
    status: false,
    css: "",
  },
  totalAccountsSelected: {
    css: "",
  },
  createdDateSelected: {
    css: "",
  },
};

const ProjectsSlice = createSlice({
  name: "projectSlice",
  initialState,
  reducers: {
    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setIsSortedByProjectNameAscending: (state, action) => {
      state.isSortedByProjectNameAscending = action.payload;
    },
    setIsSortedByAccountsNumberAscending: (state, action) => {
      state.isSortedByTotalAccountsAscending = action.payload;
    },
    setIsCreatedDateLatest: (state, action) => {
      state.isCreatedDateLatest = action.payload;
    },
    setProjectNameSelected: (state, action) => {
      state.projectNameSelected = action.payload;
    },
    setTotalAccountsSelected: (state, action) => {
      state.totalAccountsSelected = action.payload;
    },
    setCreatedDateSelected: (state, action) => {
      state.createdDateSelected = action.payload;
    },
  },
});

export default ProjectsSlice.reducer;
export const {
  setProjectList,
  setIsSortedByProjectNameAscending,
  setProjectNameSelected,
  setTotalAccountsSelected,
  setIsSortedByAccountsNumberAscending,
  setIsCreatedDateLatest,
  setCreatedDateSelected,
} = ProjectsSlice.actions;
