import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequestSettingDateFilterStatus,
  setRequestSettingSinceDate,
  setRequestSettingsModal,
  setRequestSettingUntilDate,
  setUseListStatus,
  setUseSingleProfile,
} from "./ModalSlice";
import { setUsername } from "../Home/HomeSlice";
import { useAppSelector } from "../../app/hooks";

export const RequestSettingsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state) => state.modalSlice.requestSettingsModal.status,
  );
  const sinceDate = useSelector(
    (state) => state.modalSlice.requestSettingSinceDate.sinceDate,
  );
  const untilDate = useSelector(
    (state) => state.modalSlice.requestSettingUntilDate.untilDate,
  );
  const dateFilterStatus = useSelector(
    (state) => state.modalSlice.requestSettingDateFilterStatus.status,
  );

  const useListStatus = useSelector(
    (state) => state.modalSlice.useListStatus.status,
  );

  const useSingleProfile = useSelector(
    (state) => state.modalSlice.useSingleProfile.status,
  );

  const [tempSinceDate, setTempSinceDate] = useState(
    new Date(sinceDate * 1000),
  );
  const [tempUntilDate, setTempUntilDate] = useState(
    new Date(untilDate * 1000),
  );
  const [tempDateFilterStatus, setTempDateFilterStatus] =
    useState(dateFilterStatus);
  const [tempUseListStatus, setTempUseListStatus] = useState(useListStatus);
  const [tempUseSingleProfile, setTempUseSingleProfile] =
    useState(useSingleProfile);

  const closeModal = () => {
    dispatch(setRequestSettingsModal({ status: false }));
  };

  const saveSettings = () => {
    const unixSinceDateTimestamp = Math.floor(tempSinceDate.getTime() / 1000);
    const unixUntilDateTimestamp = Math.floor(tempUntilDate.getTime() / 1000);

    console.log("Unix Since Date:", unixSinceDateTimestamp);
    console.log("Unix Until Date:", unixUntilDateTimestamp);

    dispatch(setRequestSettingSinceDate({ sinceDate: unixSinceDateTimestamp }));
    dispatch(setRequestSettingUntilDate({ untilDate: unixUntilDateTimestamp }));
    dispatch(
      setRequestSettingDateFilterStatus({ status: tempDateFilterStatus }),
    );
    dispatch(setUseListStatus({ status: tempUseListStatus }));
    dispatch(setUseSingleProfile({ status: tempUseSingleProfile }));

    closeModal();
  };

  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;

    const date = new Date(dateString);
    const timestamp = date.getTime();
    if (!timestamp && timestamp !== 0) return false;

    return date.toISOString().slice(0, 10) === dateString;
  }

  function handleSinceDateChange(e) {
    const value = e.target.value;
    if (isValidDate(value)) {
      setTempSinceDate(new Date(value));
    } else {
      console.warn("Invalid date input:", value);
    }
  }

  function handleUntilDateChange(e) {
    const value = e.target.value;
    if (isValidDate(value)) {
      setTempUntilDate(new Date(value));
    } else {
      console.warn("Invalid date input:", value);
    }
  }

  const dateFilterStatusHandler = (e) => {
    setTempDateFilterStatus(e.target.checked);
  };

  const useListStatusHandler = (e) => {
    setTempUseListStatus(e.target.checked);
  };

  const useSingleProfileModeHandler = (e) => {
    setTempUseSingleProfile(e.target.checked);
  };

  const currentSelectedProject = useAppSelector(
    (state) => state.homeSlice.currentSelectedProject,
  );

  useEffect(() => {
    if (tempUseListStatus) {
      //Do nothing
      if (currentSelectedProject.projectList[0] !== undefined) {
        dispatch(
          setUsername({
            value: currentSelectedProject.projectList[0],
          }),
        );
      }
    } else if (tempUseListStatus === false) {
      console.log("false ");
      setTempUseSingleProfile(true);
    }
  }, [tempUseListStatus]);

  useEffect(() => {
    if (tempUseSingleProfile) {
      //Do nothing
    } else if (tempUseSingleProfile === false) {
      console.log("false ");
      setTempUseListStatus(true);
    }
  }, [tempUseSingleProfile]);

  useEffect(() => {
    if (isOpen) {
      setTempSinceDate(new Date(sinceDate * 1000));
      setTempUntilDate(new Date(untilDate * 1000));
      setTempDateFilterStatus(dateFilterStatus);
      setTempUseListStatus(useListStatus);
      setTempUseSingleProfile(useSingleProfile);
    }
  }, [isOpen, sinceDate, untilDate, dateFilterStatus]);

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-instagram-dark bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-bold leading-6 text-white py-2"
                  >
                    Settings
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-center items-center my-2">
                      <label
                        className="text-instagram-light text-lg font-semibold mx-2 w-[100px]"
                        htmlFor="since"
                      >
                        Since
                      </label>
                      <input
                        type="date"
                        id="since"
                        value={formatDate(tempSinceDate)}
                        onChange={handleSinceDateChange}
                        className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
                      />
                    </div>
                    <div className="flex flex-row justify-center items-center my-2">
                      <label
                        className="text-instagram-light text-lg font-semibold mx-2 w-[100px]"
                        htmlFor="until"
                      >
                        Until
                      </label>
                      <input
                        type="date"
                        id="until"
                        value={formatDate(tempUntilDate)}
                        onChange={handleUntilDateChange}
                        className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
                      />
                    </div>

                    <div className="flex flex-row justify-between items-center my-2">
                      <label
                        className="text-instagram-light text-lg font-semibold mx-2 mr-4 w-[100px]"
                        htmlFor="until"
                      >
                        Date Filter{" "}
                      </label>
                      <input
                        type="checkbox"
                        id="checkbox"
                        className="w-4 h-4 cursor-pointer  text-instagram-lightgrey rounded-3xl border-2 border-instagram-lightgrey focus:ring-2 focus:ring-offset-0 focus:ring-instagram-lightgrey"
                        checked={tempDateFilterStatus}
                        onChange={dateFilterStatusHandler}
                      />
                    </div>

                    <div className="flex flex-row justify-between items-center my-2">
                      <label
                        className="text-instagram-light text-lg font-semibold mx-2 mr-4 w-[100px]"
                        htmlFor="until"
                      >
                        Use List{" "}
                      </label>
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={tempUseListStatus}
                        onChange={useListStatusHandler}
                        className="w-4 h-4 cursor-pointer  text-instagram-lightgrey rounded-3xl border-2 border-instagram-lightgrey focus:ring-2 focus:ring-offset-0 focus:ring-instagram-lightgrey"
                      />
                    </div>

                    <div className="flex flex-row justify-between items-center my-2">
                      <label
                        className="text-instagram-light text-lg font-semibold mx-2 mr-4 w-[100px]"
                        htmlFor="until"
                      >
                        Single Profile{" "}
                      </label>
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={tempUseSingleProfile}
                        onChange={useSingleProfileModeHandler}
                        className="w-4 h-4 cursor-pointer  text-instagram-lightgrey rounded-3xl border-2 border-instagram-lightgrey focus:ring-2 focus:ring-offset-0 focus:ring-instagram-lightgrey"
                      />
                    </div>

                    <br />
                    <br />
                    {/* Buttons */}
                    <div className="flex flex-row gap-4">
                      <button
                        className="w-fit h-10 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:text-instagram-light ring-2 ring-instagram-light font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                      <button
                        onClick={saveSettings}
                        className="w-fit h-10 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:text-instagram-light ring-2 ring-instagram-light font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RequestSettingsModal;
