import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProfileInfoModalStatus } from "./ModalSlice";
import { useAppSelector } from "../../app/hooks";

export const ProfileInfoModal = () => {
  const dispatch = useDispatch();

  const isOpen = useAppSelector(
    (state) => state.modalSlice.profileInfoModal.status,
  );
  const profileInfoModal = useAppSelector(
    (state) => state.modalSlice.profileInfoModal,
  );

  const visitProfile = () => {
    const link = `https://instagram.com/${profileInfoModal.username || "instagram"}`;
    window.open(link, "_blank");
  };

  const openProfilePicture = () => {
    const link = profileInfoModal.profile_picture_url;
    window.open(link, "_blank");
  };

  const closeModal = () => {
    const tempProfileData = { ...profileInfoModal, status: false };
    dispatch(setProfileInfoModalStatus(tempProfileData));
  };

  // useEffect(() => {
  //   console.log(profileInfoModal);
  // }, [profileInfoModal]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-instagram-dark bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-bold leading-6 text-white py-2"
                  >
                    @{profileInfoModal.username}'s profile
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <div className="w-32 h-32">
                      {profileInfoModal.profile_picture_url && (
                        <img
                          src={profileInfoModal.profile_picture_url}
                          className="rounded-2xl"
                          alt="Profile"
                          onClick={openProfilePicture}
                        />
                      )}
                    </div>

                    <div className="flex flex-row gap-x-10 mt-4">
                      <div className="flex flex-col justify-center">
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          Name
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          Username
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          ID
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          Followers
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          Following
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-left">
                          Total Media
                        </h3>
                      </div>

                      <div className="flex flex-col">
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.name}
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.username || "instagram"}
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.id}
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.followersCount}
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.followsCount}
                        </h3>
                        <h3 className="text-instagram-light font-semibold text-lg text-right">
                          {profileInfoModal.totalMediaCount}
                        </h3>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="flex flex-col">
                        <h3 className="text-instagram-light font-semibold text-lg text-center">
                          Bio
                        </h3>

                        <h3 className="text-instagram-light font-semibold text-lg text-center">
                          {profileInfoModal.biography}
                        </h3>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        class="w-fit h-10 text-instagram-dark bg-instagram-lightgrey  hover:bg-transparent  hover:text-instagram-light ring-2 ring-instagram-light font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                        onClick={visitProfile}
                      >
                        Visit Profile
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ProfileInfoModal;
