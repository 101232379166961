import { useEffect, useState } from "react";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../app/firebase";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { ReactComponent as LoginIcon } from "../../assets/login.svg";
import { LoaderModal } from "../Modals/LoaderModal";
import { useAppDispatch } from "../../app/hooks";
import { setLoaderModalStatus } from "../Modals/ModalSlice";
export default function Login() {
  const [email, setEmail] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });

  const [password, setPassword] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });

  const [isValid, setIsValid] = useState(false);
  const [user, loading] = useAuthState(auth);
  //   const router = useRouter();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      window.location.replace("/projects");
    }
  }, [user, loading]);

  const loginToFirebase = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Logging In",
      }),
    );
    let success = await logInWithEmailAndPassword(email.value, password.value);
    if (!success) {
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Logging In",
        }),
      );
    }
  };

  const goToRegister = () => {
    window.location.replace("/register");
  };

  useEffect(() => {
    const formValid = email.isValid && password.isValid;
    setIsValid(formValid);
  }, [email, password]);

  const emailHandler = (event) => {
    const email_temp = { ...email };
    email_temp.value = event.target.value;
    email_temp.isValid = true;
    email_temp.hasChanged = true;
    email_temp.feedback = "";
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email_temp.value.search(re) === -1) {
      email_temp.isValid = false;
      email_temp.feedback = "Enter a valid email address";
    }
    setEmail(email_temp);
  };

  const passwordHandler = (event) => {
    const password_inp = { ...password };
    password_inp.value = event.target.value;
    password_inp.hasChanged = true;
    password_inp.isValid = true;
    password_inp.feedback = "";
    if (event.target.value.toString().length === 0) {
      password_inp.isValid = false;
      password_inp.feedback = "Please enter a password";
    }
    setPassword(password_inp);
  };

  return (
    <>
      <Nav />
      <div className="flex justify-center animate-fade-in-down">
        <div className="flex flex-col mt-44 w-[400px] h-[600px]">
          <div className="">
            <h1 className="font-bold text-center text-white text-2xl py-2">
              Sign In
            </h1>

            <input
              onChange={emailHandler}
              value={email.value}
              type="text"
              className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
              placeholder={"Enter Email"}
            />
            <p className="text-center text-sm text-rose-700">
              {email.feedback}
            </p>

            <input
              onChange={passwordHandler}
              value={password.value}
              type="password"
              className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
              placeholder={"Enter Password"}
            />
            <p className="text-center text-sm text-rose-700">
              {password.feedback}
            </p>

            <div className="flex justify-center mt-4">
              <button
                disabled={!isValid}
                onClick={loginToFirebase}
                className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
              >
                <span>Log In</span>
                <LoginIcon width={24} height={20} />
              </button>
            </div>

            <div>
              <p className="text-center text-lg font-semibold text-white py-8">
                Need an account?{" "}
                <Link
                  onClick={goToRegister}
                  className="underline decoration-twitterblue decoration-solid decoration-2 underline-offset-4 hover:text-twitterblue hover:decoration-white font-bold"
                >
                  Register
                </Link>{" "}
                now
              </p>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal />
    </>
  );
}
