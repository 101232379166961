import { useAuthState } from "react-firebase-hooks/auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CreateProjectModal from "../Modals/CreateProjectModal";
import {
  setCreateProjectModal,
  setDeleteConfirmationStatus,
  setEditProjectModal,
  setLoaderModalStatus,
} from "../Modals/ModalSlice";
import Nav from "../Nav/Nav";
import {
  auth,
  deleteProjectEntry,
  getExistingProjects,
} from "../../app/firebase";
import { useEffect, useState } from "react";
import {
  setCreatedDateSelected,
  setIsCreatedDateLatest,
  setIsSortedByAccountsNumberAscending,
  setIsSortedByProjectNameAscending,
  setProjectList,
  setProjectNameSelected,
  setTotalAccountsSelected,
} from "./ProjectsSlice";
import { setCurrentSelectedProject, setUsername } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import EditProjectModal from "../Modals/EditProjectModal";
import { ReactComponent as Add } from "../../assets/add.svg";
import { ReactComponent as AlphabetSort } from "../../assets/alphabetsort.svg";
import { ReactComponent as NumberSort } from "../../assets/numberSort.svg";
import { ReactComponent as CalendarSort } from "../../assets/calendar.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";

import { LoaderModal } from "../Modals/LoaderModal";
import DeleteConfirmationModal from "../Modals/DeleteConfirmationModal";

const Projects = () => {
  let mediaData;

  const [user, loading] = useAuthState(auth);
  const [isIconWhite, setIsIconWhite] = useState(false);
  const [isTotalAccountsIconWhite, setIsTotalAccountsIconWhite] =
    useState(false);
  const [isDateCreatedIconWhite, setIsDateCreatedIconWhite] = useState(false);

  let dispatch = useAppDispatch();
  const navigate = useNavigate();

  let projectList = useAppSelector((state) => state.projectSlice.projectList);
  let isSortedByProjectNameAscending = useAppSelector(
    (state) => state.projectSlice.isSortedByProjectNameAscending,
  );
  let isSortedByTotalAccountsAscending = useAppSelector(
    (state) => state.projectSlice.isSortedByTotalAccountsAscending,
  );
  let isCreatedDateLatest = useAppSelector(
    (state) => state.projectSlice.isCreatedDateLatest,
  );

  let projectNameSelected = useAppSelector(
    (state) => state.projectSlice.projectNameSelected,
  );
  let totalAccountsSelected = useAppSelector(
    (state) => state.projectSlice.totalAccountsSelected,
  );
  let createdDateSelected = useAppSelector(
    (state) => state.projectSlice.createdDateSelected,
  );

  const createNewProject = () => {
    //Invoke modal - Add a routine to create a doc in firebase to store all the usernames
    dispatch(
      setCreateProjectModal({
        status: true,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const getExistingProjectsList = async () => {
    if (user) {
      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Retriving Your Projects",
        }),
      );
      let existingList = await getExistingProjects(user.uid);
      console.log(existingList);
      dispatch(setProjectList(existingList));
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Retriving Your Projects",
        }),
      );
    }
  };

  const openHomeWithProject = (projectName, projectList) => {
    dispatch(
      setCurrentSelectedProject({
        projectName: projectName,
        projectList: projectList,
      }),
    );
    // window.location.replace("/");
    navigate("/home", {
      state: {
        projectName: projectName,
        projectList: projectList,
      },
    });
    dispatch(
      setUsername({
        value: projectList[0],
      }),
    );
  };

  const editEntry = (item) => {
    console.log(item);
    dispatch(
      setEditProjectModal({
        status: true,
        projectName: item.projectName,
        createdDate: item.createdDate,
        lastUsed: item.lastUsed,
        lastModified: item.lastModified,
        usernames: item.usernameList,
      }),
    );
  };

  const deleteEntry = async (item) => {
    dispatch(
      setDeleteConfirmationStatus({
        status: true,
        projectName: item.projectName,
        entryUID: user.uid,
        entryItem: item,
      }),
    );

    console.log(user.uid, item);
    //  await deleteProjectEntry(user.uid,item);
  };

  const sortByProjectName = () => {
    let dataTemp = [...projectList];
    let sortedArray;
    if (isSortedByProjectNameAscending) {
      sortedArray = dataTemp.sort((a, b) =>
        b.projectName.localeCompare(a.projectName),
      );

      setIsIconWhite(true);
      setIsTotalAccountsIconWhite(false);
      setIsDateCreatedIconWhite(false);

      dispatch(
        setProjectNameSelected({
          status: true,
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    } else {
      sortedArray = dataTemp.sort((a, b) =>
        a.projectName.localeCompare(b.projectName),
      );
      dispatch(
        setProjectNameSelected({
          status: true,
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    }

    dispatch(
      setIsSortedByProjectNameAscending(!isSortedByProjectNameAscending),
    );
    dispatch(setProjectList(sortedArray));
  };

  const sortByAccountsNumber = () => {
    let dataTemp = [...projectList];
    let sortedArray;
    if (isSortedByTotalAccountsAscending) {
      sortedArray = [...dataTemp].sort(
        (a, b) => b.usernameList.length - a.usernameList.length,
      );

      setIsIconWhite(false);
      setIsTotalAccountsIconWhite(true);
      setIsDateCreatedIconWhite(false);

      dispatch(
        setTotalAccountsSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    } else {
      sortedArray = [...dataTemp].sort(
        (a, b) => a.usernameList.length - b.usernameList.length,
      );
      dispatch(
        setTotalAccountsSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );

      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );

      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    }

    dispatch(
      setIsSortedByAccountsNumberAscending(!isSortedByTotalAccountsAscending),
    );
    dispatch(setProjectList(sortedArray));
  };

  const sortByCreatedDate = () => {
    let dataTemp = [...projectList];
    console.log(dataTemp);
    let sortedLatest;
    if (isCreatedDateLatest) {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      );

      setIsIconWhite(false);
      setIsTotalAccountsIconWhite(false);
      setIsDateCreatedIconWhite(true);

      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
    } else {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      dispatch(
        setCreatedDateSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
    }

    dispatch(setIsCreatedDateLatest(!isCreatedDateLatest));

    dispatch(setProjectList(sortedLatest));
  };

  useEffect(() => {
    console.log(projectList);
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      if (projectList.length === 0) {
        getExistingProjectsList();
      } else {
        //Do nothing
      }
    } else {
      window.location.replace("/login");
    }
  }, [user, loading]);

  return (
    <>
      <Nav />
      <div className="flex justify-center items-center mt-24 flex-col">
        <div className="flex justify-end"></div>

        <div className="my-4 mx-10 flex flex-col justify-center w-[1000px]">
          <div className="flex justify-end">
            <button
              className="w-fit h-10 my-5 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
              onClick={createNewProject}
            >
              <span>Create Project</span>
              <Add width={24} height={24} />
            </button>
          </div>

          {projectList !== undefined && (
            <p className="text-instagram-light text-end mr-2 font-semibold">
              {projectList.length} Projects
            </p>
          )}

          <div className="flex flex-row justify-between w-full max-w-[1000px] bg-instagram-lightgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-dark font-medium px-5 py-5">
            <div className="relative">
              <div
                className={
                  `flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ` +
                  projectNameSelected.css
                }
                onClick={sortByProjectName}
              >
                <p className="unselectable">Project Name</p>

                <AlphabetSort
                  width={24}
                  height={24}
                  className={isIconWhite ? "invert" : "currentColor"}
                />
              </div>
            </div>

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${totalAccountsSelected.css}`}
              onClick={sortByAccountsNumber}
            >
              <p className="unselectable">Total Accounts</p>

              <NumberSort
                width={24}
                height={24}
                className={isTotalAccountsIconWhite ? "invert" : "currentColor"}
              />
            </div>

            {/* <div
              className="hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md"
              //   onClick={sortByLikes}
            >
              <p className="unselectable">Last Used</p>
            </div> */}

            {/* <div
              className="hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md"
              //   onClick={sortByLikes}
            >
              <p className="unselectable">Date Modified</p>
            </div> */}

            <div
              className={`flex gap-1 hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md cursor-pointer ${createdDateSelected.css}`}
              onClick={sortByCreatedDate}
            >
              <p className="unselectable">Date Created</p>
              <CalendarSort
                width={24}
                height={24}
                className={isDateCreatedIconWhite ? "invert" : "currentColor"}
              />
            </div>

            <div
              className="hover:bg-instagram-darkgrey hover:text-instagram-lightgrey px-5 py-2 rounded-md"
              //   onClick={sortByReach}
            >
              <p className="unselectable">Action</p>
            </div>
          </div>

          {projectList !== undefined && projectList.length > 0 ? (
            projectList.map((item) => (
              <button
                className="flex flex-row justify-between w-full max-w-[1000px] bg-instagram-darkgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-lightgrey font-medium hover:bg-instagram-lightgrey hover:text-instagram-darkgrey px-5 py-5"
                key={item.id}
                onClick={() =>
                  openHomeWithProject(item.projectName, item.usernameList)
                }
              >
                <div className="flex-1 text-left pl-5">
                  <p>{item.projectName}</p>
                </div>
                <div className="flex-1 text-center">
                  <p>{item.usernameList.length}</p>
                </div>
                {/* 
                <div className="flex-1 text-center">
                  <p>
                    {new Date(item.lastUsed).getFullYear() +
                      "-" +
                      (new Date(item.lastUsed).getMonth() + 1) +
                      "-" +
                      new Date(item.lastUsed).getDate()}
                  </p>
                </div>

                <div className="flex-1 text-center">
                  <p>
                    {new Date(item.lastModified).getFullYear() +
                      "-" +
                      (new Date(item.lastModified).getMonth() + 1) +
                      "-" +
                      new Date(item.lastModified).getDate()}
                  </p>
                </div> */}

                <div className="flex-1 text-right">
                  <p>
                    {new Date(item.createdDate).getFullYear() +
                      "-" +
                      (new Date(item.createdDate).getMonth() + 1) +
                      "-" +
                      new Date(item.createdDate).getDate()}
                  </p>
                </div>

                <div className="flex-1 text-right pr-5">
                  <button
                    className="hover:scale-110"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteEntry(item);
                    }}
                  >
                    <DeleteIcon width={20} height={20} />
                  </button>
                </div>

                {/* <div className="flex-1 text-center">
                <button
          className="hover:underline"
          onClick={(e) => {
            e.stopPropagation();
            editEntry(item);
          }}
        >
          Edit
        </button>

        <button
          className="hover:underline"
          onClick={(e) => {
            e.stopPropagation();
            deleteEntry(item);
          }}
        >
          Delete
        </button>
                </div> */}
              </button>
            ))
          ) : (
            <button className="flex flex-row justify-center w-full max-w-[1000px] bg-instagram-darkgrey my-2 gap-x-10 border border-instagram-lightgrey rounded-lg text-instagram-lightgrey font-medium hover:bg-instagram-lightgrey hover:text-instagram-darkgrey px-5 py-5">
              No Projects Available At The Moment
            </button>
          )}
        </div>
      </div>
      <CreateProjectModal />
      <EditProjectModal /> <LoaderModal />
      <DeleteConfirmationModal />
    </>
  );
};

export default Projects;
