import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";

import Home from "./Components/Home/Home";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./Components/TermsOfService/TermsOfService";
import Nav from "./Components/Nav/Nav";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Projects from "./Components/Projects/Projects";
import { useEffect, useState } from "react";
import NotFound from "./Components/NotFound/NotFound";
import NotSupported from "./Components/NotSupported/NotSupported";

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1281);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1281);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      {!isSmallScreen ? (
        <div className="App">
          <Nav />
          <Routes>
            <Route path={"*"} element={<NotFound />} />

            <Route path={"/"} element={<Projects />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path={"/projects"} element={<Projects />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </div>
      ) : (
        <div className="App">
          <Routes>
            <Route path={"*"} element={<NotSupported />} />
          </Routes>
        </div>
      )}
    </Router>
  );
}

export default App;
