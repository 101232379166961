import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: {
    value: "",
  },
  output: {
    value: "",
  },
  profileData: {
    profile_picture_url: "",
    name: "",
    username: "",
    id: "",
    followersCount: "",
    totalMediaCount: "",
  },
  mediaData: [],
  mediaDataBackup: [],
  currentSelectedProject: {
    projectName: "",
    projectList: [],
  },
  isDescending: true,
  isDescendingComments: true,
  isLatest: true,
  isEngagement: true,
  isReach: true,
  isUsername: true,
  likesSelected: {
    css: "",
  },
  commentsSelected: {
    css: "",
  },

  dateSelected: {
    css: "",
  },

  engagementSelected: {
    css: "",
  },

  reachSelectedSelected: {
    css: "",
  },
  usernameSelected: {
    css: "",
  },
  mediaTypeSelected: {
    css: "",
  },

  multipleMediaData: [],
  multipleMediaDataBackup: [],
};

const HomeSlice = createSlice({
  name: "homeSlice",
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setOutput: (state, action) => {
      state.output = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setMediaData: (state, action) => {
      state.mediaData = action.payload;
    },
    setMediaDataBackup: (state, action) => {
      state.mediaDataBackup = action.payload;
    },
    setIsDescending: (state, action) => {
      state.isDescending = action.payload;
    },
    setIsDescendingComments: (state, action) => {
      state.isDescendingComments = action.payload;
    },
    setIsLatest: (state, action) => {
      state.isLatest = action.payload;
    },
    setIsEngagement: (state, action) => {
      state.isEngagement = action.payload;
    },
    setIsReach: (state, action) => {
      state.isReach = action.payload;
    },
    setIsUsername: (state, action) => {
      state.isUsername = action.payload;
    },
    setCurrentSelectedProject: (state, action) => {
      state.currentSelectedProject = action.payload;
    },
    setLikesSelected: (state, action) => {
      state.likesSelected = action.payload;
    },
    setCommentsSelected: (state, action) => {
      state.commentsSelected = action.payload;
    },
    setDateSelected: (state, action) => {
      state.dateSelected = action.payload;
    },
    setEngagementSelected: (state, action) => {
      state.engagementSelected = action.payload;
    },
    setReachSelected: (state, action) => {
      state.reachSelectedSelected = action.payload;
    },
    setUsernameSelected: (state, action) => {
      state.usernameSelected = action.payload;
    },
    setMediaTypeSelected: (state, action) => {
      state.mediaTypeSelected = action.payload;
    },
    setMultipleMediaData: (state, action) => {
      state.multipleMediaData = action.payload;
    },
    setMultipleMediaDataBackup: (state, action) => {
      state.multipleMediaDataBackup = action.payload;
    },
  },
});

export default HomeSlice.reducer;
export const {
  setUsername,
  setOutput,
  setProfileData,
  setMediaData,
  setIsDescending,
  setIsDescendingComments,
  setMediaDataBackup,
  setIsLatest,
  setIsEngagement,
  setIsReach,
  setCurrentSelectedProject,
  setLikesSelected,
  setCommentsSelected,
  setDateSelected,
  setReachSelected,
  setEngagementSelected,
  setMediaTypeSelected,
  setMultipleMediaData,
  setMultipleMediaDataBackup,
  setIsUsername,
  setUsernameSelected,
} = HomeSlice.actions;
