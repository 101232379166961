import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { setCreateProjectModal, setLoaderModalStatus } from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/add.svg";
import { LoaderModal } from "./LoaderModal";

const CreateProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isOpen = useAppSelector(
    (state) => state.modalSlice.createProjectModal.status,
  );
  let projectName = useAppSelector(
    (state) => state.modalSlice.createProjectModal.projectName,
  );
  let createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.createProjectModal,
  );

  let usernameList = useAppSelector(
    (state) => state.modalSlice.createProjectModal.usernames,
  );

  let createdDate = useAppSelector(
    (state) => state.modalSlice.createProjectModal.createdDate,
  );
  let lastModified = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastModified,
  );
  let lastUsed = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastUsed,
  );

  const [newUsername, setNewUsername] = useState(""); // State for input field
  const [user, loading] = useAuthState(auth);

  const closeModal = () => {
    dispatch(
      setCreateProjectModal({
        status: false,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const projectNameHandler = (e) => {
    let temp = { ...createProjectModalObject };
    temp.projectName = e.target.value;
    dispatch(setCreateProjectModal(temp));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      let temp = { ...createProjectModalObject };
      temp.usernames = [...temp.usernames, newUsername];
      dispatch(setCreateProjectModal(temp));
      setNewUsername(""); // Clear the input after adding
    }
  };

  const createProjectFirebase = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Creating Project",
      }),
    );
    closeModal();
    let uid = user.uid;
    let projectNameTemp = projectName;
    let userList = [...new Set(usernameList)];
    let cDate = createdDate;
    let lModified = lastModified;
    let lUsed = lastUsed;
    let success = await addUserProject(
      uid,
      projectNameTemp,
      cDate,
      lModified,
      lUsed,
      userList,
    );
    if (success) {
      //Redirect to dashboard/Home
      //SET CURRENT PROJECT SOMEHOW

      dispatch(
        setCurrentSelectedProject({
          projectName: projectName,
          projectList: userList,
        }),
      );

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Creating Project",
        }),
      );

      navigate("/home", {
        state: {
          projectName: projectName,
          projectList: userList,
        },
      });
    } else {
      //Display error
      console.log("E");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-instagram-dark bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl text-center font-bold leading-6 text-white py-2"
                  >
                    Create New Project
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <input
                      placeholder="Enter Project Name"
                      value={projectName}
                      onChange={projectNameHandler}
                      className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
                    />

                    <div className="flex flex-col justify-center items-center w-full">
                      <input
                        placeholder="Add Username"
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        className="w-full rounded-lg text-center py-2.5 my-5 font-medium outline outline-4 outline-instagram-lightgrey"
                      />
                      <button
                        onClick={addUsernameHandler}
                        className="w-fit cursor-pointer h-10 my-5 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
                      >
                        <span>Add</span>

                        <Add width={24} height={24} />
                      </button>
                    </div>

                    <div className="mt-4 w-full h-40 overflow-y-auto bg-instagram-lightgrey rounded-lg p-4">
                      {usernameList.length > 0 ? (
                        <ul className="space-y-2">
                          {usernameList.map((username, index) => (
                            <li key={index} className="text-instagram-dark">
                              {username}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-gray-400">No usernames added yet.</p>
                      )}
                    </div>

                    <div className="mt-4">
                      <button
                        onClick={createProjectFirebase}
                        className="w-fit cursor-pointer h-10 my-2 text-instagram-dark bg-instagram-lightgrey hover:bg-transparent hover:invert ring-2 ring-instagram-light hover:ring-instagram-dark font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 flex items-center space-x-2"
                      >
                        <span>Create Project</span>
                        <Add width={24} height={24} />{" "}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
    </>
  );
};

export default CreateProjectModal;
